<template>
    <section id='final' v-if='loaded'>
        <div class='parent'>
            <h1>
                תודה על {{ $parse('השתתפותך' ,'השתתפותך' ,'השתתפותכם') }}
                במחקר!
            </h1>
            <div v-if='hasCorona || hasCorona === null'>
                כדי שנוכל לקבל תמונה יותר ברורה,
                נשמח אם {{ $parse('תוכל' ,'תוכלי' ,'תוכלו') }}
                לחזור למלא את השאלון גם בימים הבאים, עד ש{{ $parse('תחלים', 'תחלימי', 'תחלימו')}}
                מהקורונה. הכניסה תיעשה באמצעות אותו הקישור ש{{ $parse('נכנסת', 'נכנסת', 'נכנסתם') }}
                איתו הפעם.
            </div>
            <div>
                {{ $parse('אתה מוזמן' , 'את מוזמנת' , 'אתם מוזמנים') }}
                לפנות אלי בכל שאלה שיש {{ $parse('לך' , 'לך' , 'לכם') }}
                במייל michal.haran@gmail.com או בטלפון/ווטסאפ <div id='phone'>{{ $store.state.studyPhone }}</div>.
            </div>
            <div>
                {{ $parse('תוכל' , 'תוכלי' , 'תוכלו') }} <a :href='pdfPath'>ללחוץ כאן</a>
                כדי להוריד את טופס ההסכמה ש{{ $parse('מילאת' , 'מילאת' , 'מילאתם') }}
                בתחילת השאלון.
            </div>
            <div>
                חשוב שיהיו כמה שיותר אנשים שישתתפו במחקר שלנו,
                כדי שנוכל לקבל תוצאות כמה שיותר מהימנות.
                נשמח מאוד אם {{ $parse('תשתף' , 'תשתפי' , 'תשתפו') }}
                את כתובת האתר שלנו (<a href='https://coronastudy.co.il' target='_blank'>coronastudy.co.il</a>)
                כדי שאנשים נוספים יצטרפו למחקר.
            </div>
            <img alt='' src='https://sharedchart.care/storage/chat/b27c2a24d4469dc7db3c'/>
            <div id='comments' :class='{ withBorder: comments.status !== "sent" }'>
                <div v-if='comments.status !== "sent"'>
                    יש לך הערות על המראה של השאלון או על התוכן שלו? נשמח אם
                    {{ $parse('תרשום' , 'תרשמי') }}
                    אותן כאן:
                    <textarea v-model='comments.val'></textarea>
                    <button type='button' study-button @click='sendComments()'>
                        <span v-if='!comments.status'>שלח הערות</span>
                        <span v-else>שולח...</span>
                    </button>
                </div>
                <h3 v-else>
                    תודה על ההערות! אנחנו מאוד מעריכים את זה
                </h3>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { pdfPath, sendMessage } from '../helpers';
import { load } from '../Loading.vue';
import { setHasCorona } from '../module';

type data = { comments: { status: 'sending' | 'sent' | null, val: string }, loaded: boolean };

export default defineComponent({
    async beforeMount(){
        if(this.$store.hasModule('study'))
            return;

        this.loaded = false;
        const data = await load();
        if('notFound' in data || !data.usedAt)
            return this.$router.push(`/token/${this.$route.params.token}`);

        setHasCorona(Boolean(data.hasCorona));
        this.loaded = true;
    },
    data: (): data => ({ comments: { status: null, val: '' }, loaded: true }),
    computed: {
        hasCorona(){
            return this.$store.state.study!.hasCorona;
        },
        pdfPath(){
            return pdfPath();
        }
    },
    methods: {
        async sendComments(){
            this.comments.status = 'sending';
            await sendMessage(this.comments.val, 'comments');
            this.comments.status = 'sent';
        }
    }
});
</script>

<style lang="scss">
#final .parent {
    margin: 3rem 0;
    max-width: 40rem;
    
    & > div {
        margin: 1rem 0;
        padding: 0 1.5rem;
    }
    & img {
        margin: 0 auto;
        max-width: 10rem;
    }

    & #phone {
        display: inline;
    }

    & #comments {
        &.withBorder {
            border-top: $borderLight;
        }

        padding-top: 1.5rem;

        & textarea {
            width: 100%;
            margin: 1rem 0;
            box-shadow: unset;
        }

        & h3 {
            background-color: $swatchA;
            border-radius: $corner;
            @include shadow;
            margin: 0 auto 1rem;
            width: fit-content;
            padding: 1rem;
            font-size: 1.1rem;
        }

        & button {
            margin: 2rem auto 1rem;
            display: block;
        }
    }
}
</style>