
import { defineComponent } from '@vue/runtime-core';
import { pdfPath, sendMessage } from '../helpers';
import { load } from '../Loading.vue';
import { setHasCorona } from '../module';

type data = { comments: { status: 'sending' | 'sent' | null, val: string }, loaded: boolean };

export default defineComponent({
    async beforeMount(){
        if(this.$store.hasModule('study'))
            return;

        this.loaded = false;
        const data = await load();
        if('notFound' in data || !data.usedAt)
            return this.$router.push(`/token/${this.$route.params.token}`);

        setHasCorona(Boolean(data.hasCorona));
        this.loaded = true;
    },
    data: (): data => ({ comments: { status: null, val: '' }, loaded: true }),
    computed: {
        hasCorona(){
            return this.$store.state.study!.hasCorona;
        },
        pdfPath(){
            return pdfPath();
        }
    },
    methods: {
        async sendComments(){
            this.comments.status = 'sending';
            await sendMessage(this.comments.val, 'comments');
            this.comments.status = 'sent';
        }
    }
});
